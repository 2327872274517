import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

// utils
import i18n from '../../utils/i18n';

import * as layout from '../../styles/helpers/layout';

const supportedBrowsersFAQ =
  'https://www.lumosity.com/zendesk/authentication?return_to=https://help.lumosity.com/hc/articles/202172080';

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    top: 60,
    width: '100%',
    backgroundColor: '#fcf8e3',
    textAlign: 'center',
    color: '#8e6b29',
    ...layout.centerHorizontal,
    ...layout.centerVertical
  },
  container: {
    marginLeft: 0,
    marginRight: 0
  }
});

class UnsupportedBrowserBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isUnsupportedBrowser: false };
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode) {
      this.setState({ isUnsupportedBrowser: true });
    }
  }

  render() {
    const { isUnsupportedBrowser } = this.state;
    if (isUnsupportedBrowser) {
      return (
        <div className={css(styles.wrapper)}>
          <Container className={css(styles.container)}>
            <Row>
              <Col xs="12">
                <p>{i18n.t('UnsupportedBrowserBanner.browserNotification')}</p>
                <p>
                  {ReactHtmlParser(i18n.t('UnsupportedBrowserBanner.browserRecommendation', { supportedBrowsersFAQ }))}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

export default UnsupportedBrowserBanner;
