import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, blackAt89, blackAt62, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';

// images
import background from './images/mindfulness_background.svg';
import appIcon from './images/mindfulness_icon.svg';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const styles = StyleSheet.create({
  parallaxWrapper: {
    ...layout.centerHorizontal,
    ...layout.centerVertical,
    height: 582,
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [layout.mediaQuery.maxWidth.tablet]: {
      height: 450
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      height: 415
    }
  },
  container: {
    ...layout.defaultContainerProperties
  },
  centeringRow: {
    ...layout.centerHorizontal,
    ...layout.centerVertical
  },
  centeringCol: {
    ...layout.centerHorizontal,
    textAlign: 'center'
  },
  appIcon: {
    maxHeight: 230,
    maxWidth: 400,
    [layout.mediaQuery.maxWidth.tablet]: {
      height: 280,
      width: 280,
      marginBottom: -50,
      marginTop: -50
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      height: 200,
      width: 200,
      marginBottom: -40,
      marginTop: -40
    }
  },
  headerCol: {
    marginTop: 24,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 16
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 12
    }
  },
  header: {
    ...fonts.h3,
    color: blackAt89
  },
  subheaderCol: {
    marginTop: 24,
    marginBottom: 56,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 16,
      marginBottom: 24
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 12,
      marginBottom: 20
    }
  },
  subheader: {
    ...fonts.p,
    color: blackAt62
  },
  cta: {
    ...buttons.buttonDefaults
  }
});

function Mindfulness() {
  return (
    <div className={css(styles.parallaxWrapper)}>
      <Container fluid className={css(styles.container)}>
        <Row className={css(styles.centeringRow)}>
          <Col xs="12" className={css(styles.centeringCol)}>
            <img src={appIcon} className={css(styles.appIcon)} alt={i18n.t('homePage.Mindfulness.appIconImageAlt')} />
          </Col>
          <Col xs="12" md="10" className={css(styles.centeringCol, styles.headerCol)}>
            <h3 className={css(styles.header)}>{i18n.t('homePage.Mindfulness.header')}</h3>
          </Col>
          <Col xs="11" md="8" className={css(styles.centeringCol, styles.subheaderCol)}>
            <h5 className={css(styles.subheader)}>{i18n.t('homePage.Mindfulness.content')}</h5>
          </Col>
          <Col xs="12" className={css(styles.centeringCol)}>
            <RoundedButton
              href="/en/landing_pages/mindfulness"
              styles={[styles.cta]}
              track="true"
              trackButtonPress="goto_mindfulness"
              trackLocation="body"
            >
              {i18n.t('homePage.Mindfulness.cta')}
            </RoundedButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Mindfulness;
