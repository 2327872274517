import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, whiteAt98, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from 'utils/i18n';
import ScrollReveal from 'components/utils/ScrollRevealWrapper';

// util component
import HeroWrapper from 'components/ui/layout/HeroWrapper';

// images
import background from './images/eclipse_hero_background.svg';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const styles = StyleSheet.create({
  headerRow: {
    ...layout.centerHorizontal
  },
  header: {
    ...fonts.h1,
    fontWeight: 700,
    color: whiteAt98,
    textAlign: 'center'
  },
  subheader: {
    ...fonts.p,
    display: 'inline',
    color: whiteAt98
  },
  superscript: {
    ...fonts.smallCaption,
    color: whiteAt98,
    display: 'inline',
    verticalAlign: 'super',
    padding: '0 2px',
    cursor: 'pointer'
  },
  subheaderRow: {
    ...layout.centerHorizontal,
    margin: '16px 0 24px',
    textAlign: 'center'
  },
  ctaRow: {
    ...layout.centerHorizontal
  },
  cta: {
    ...buttons.buttonDefaults
  },
  disclaimerRow: {
    ...layout.centerHorizontal,
    marginTop: 8,
    marginBottom: 50,
    [layout.mediaQuery.minWidth.desktopSmall]: {
      marginTop: 12,
      marginBottom: 100
    }
  },
  disclaimer: {
    ...fonts.smallCaption,
    color: whiteAt98
  }
});

const backgroundStyles = StyleSheet.create({
  image: {
    backgroundImage: `url(${background})`
  }
});

/*
Notes:

Header hierarchy —
content of the main header is less nutritious to search engines than the content of the subheader
therefore, subheader is an <h1> styled as a <p>, header is an <h2> styled as a <p>

Bottom margin on disclaimer —
centers the content visiually when its paired with the specific 'user with mobile' background image

*/

function EclipseHero() {
  return (
    <HeroWrapper background={background} backgroundStyles={backgroundStyles.image}>
      <Container>
        <Row className={css(styles.headerRow)}>
          <Col xs="11" sm="9">
            <h2 className={css(styles.header)} data-cy="eclipse-header">
              {i18n.t('homePage.EclipseHero.header')}
            </h2>
          </Col>
        </Row>
        <Row className={css(styles.subheaderRow)}>
          <Col xs="10" sm="7">
            <h1 className={css(styles.subheader)}>{i18n.t('homePage.EclipseHero.subheader')}</h1>
          </Col>
        </Row>
        <ScrollReveal animation="fadeIn">
          <Row className={css(styles.ctaRow)}>
            <RoundedButton
              value={i18n.t('homePage.EclipseHero.cta')}
              styles={[styles.cta]}
              track="true"
              trackButtonPress="goto_signup_get_started"
              trackLocation="body"
              href="/sign_up"
            />
          </Row>
        </ScrollReveal>
        <Row className={css(styles.disclaimerRow)}>
          <p className={css(styles.disclaimer)}>{i18n.t('homePage.EclipseHero.noPurchase')}</p>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

export default EclipseHero;
