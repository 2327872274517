import React from 'react';
import PropTypes from 'prop-types';
import { lumosDarkBlue } from '@lumoslabs/react_lux';
import i18n from 'utils/i18n';
import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import SchemaDotOrg from 'components/landing/SchemaDotOrg';
import {
  UnsupportedBrowserBanner,
  EclipseHero,
  BrainCare,
  ScienceBasedGames,
  ResearchModule,
  Mindfulness,
  FigmentBanner,
  JumboCta
} from 'components/HomePage';
import { StyleSheet, css } from 'aphrodite';
import TestimonialCardGroup from 'components/landing/TestimonialCardGroup';
import circularBackground from 'components/HomePage/images/tablets_teal.png';
import * as layout from '../styles/helpers/layout';

const styles = StyleSheet.create({
  circularBackgroundContainer: {
    backgroundImage: `url(${circularBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '48vw',
    [layout.mediaQuery.maxWidth.desktopSmall]: {
      backgroundSize: '80vw'
    }
  }
});

function HomePage({ location, pageContext: { locale, pagePath } }) {
  return (
    <Layout locale={locale} location={location} isStickyHeader accentColor={lumosDarkBlue}>
      <Metatags
        title={i18n.t('homePage.title')}
        description={i18n.t('homePage.description')}
        type="website"
        canonical={pagePath}
        locale={locale}
      />
      <div className="UnsupportedBrowserBanner">
        <UnsupportedBrowserBanner />
      </div>
      <div className="EclipseHero">
        <EclipseHero />
      </div>
      <BrainCare locale={locale} />
      <ScienceBasedGames locale={locale} />
      <TestimonialCardGroup locale={locale} />
      <div className={css(styles.circularBackgroundContainer)}>
        <ResearchModule />
      </div>
      {locale === 'en' && (
        <>
          <FigmentBanner />
          <Mindfulness />
        </>
      )}
      <JumboCta locale={locale} />
      <SchemaDotOrg />
    </Layout>
  );
}

HomePage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export default HomePage;
