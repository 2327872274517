import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { OutlineButton, blackAt62, fonts } from '@lumoslabs/react_lux';

// images
import PlayingKoi from './images/playing_koi_2.svg';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const styles = StyleSheet.create({
  container: {
    ...layout.containerWithoutTopPadding,
    overflowX: 'hidden'
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  header: {
    ...fonts.h3,
    textAlign: 'center'
  },
  subHeaderCol: {
    marginTop: 24,
    marginBottom: 40,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 16,
      marginBottom: 24
    }
  },
  subheader: {
    ...fonts.h5,
    color: blackAt62,
    textAlign: 'center'
  },
  outlineButton: {
    ...buttons.outlineButtonDefaults
  },
  imageContainer: {
    // so the cta above isn't blocked and is still clickable
    zIndex: -1,
    ...layout.overflowImageContainer
  },
  image: {
    width: '200vw',
    maxWidth: 1500,
    marginTop: -100,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: -25
    }
  }
});

function ScienceBasedGames({ locale }) {
  return (
    <Container className={css(styles.container)}>
      <Row className={css(styles.centeringRow)}>
        <Col xs="12" md="11">
          <h3 className={css(styles.header)}>{i18n.t('homePage.ScienceBasedGames.header')}</h3>
        </Col>
      </Row>
      <Row className={css(styles.centeringRow)}>
        <Col xs="12" md="10" className={css(styles.subHeaderCol)}>
          <h5 className={css(styles.subheader)}>{i18n.t('homePage.ScienceBasedGames.content')}</h5>
        </Col>
      </Row>
      <ScrollReveal animation="fadeIn">
        <Row className={css(styles.centeringRow)}>
          <OutlineButton
            value={i18n.t('homePage.ScienceBasedGames.cta')}
            styles={[styles.outlineButton]}
            track="true"
            trackButtonPress="goto_science"
            trackLocation="body"
            href={`/${locale}/science/`}
          />
        </Row>
      </ScrollReveal>
      <div className={css(styles.imageContainer)}>
        <img src={PlayingKoi} className={css(styles.image)} alt={i18n.t('homePage.ScienceBasedGames.imgAlt')} />
      </div>
    </Container>
  );
}

ScienceBasedGames.propTypes = {
  locale: PropTypes.string
};

ScienceBasedGames.defaultProps = {
  locale: 'en'
};

export default ScienceBasedGames;
