import React from 'react';
import PropTypes from 'prop-types';
import { layout, mediaQuery } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';

const heroBackgroundStyles = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
};

const styles = StyleSheet.create({
  heroWrapper: {
    ...heroBackgroundStyles,
    height: '50vh',
    minHeight: 700,
    color: 'rgba(255,255,255,0.98)',
    [mediaQuery.maxWidth.tablet]: {
      minHeight: 476
    }
  }
});

function HeroWrapper({ children, backgroundStyles }) {
  return <div className={css(styles.heroWrapper, backgroundStyles, layout.centerVertical)}>{children}</div>;
}

HeroWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundStyles: PropTypes.shape({
    _definition: PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default HeroWrapper;
