import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { mediaQuery, RoundedButton, blackAt89, blackAt62, fonts } from '@lumoslabs/react_lux';
import * as enOnly from '../../locales/enOnly.json';
import { figmentUrlBanner } from '../ui/utils/LinkUtils';
import figmentBannerSvg from './images/figmentBanner.svg';
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const backgroundColor = '#fbf8ef';

const styles = StyleSheet.create({
  image: {
    paddingBottom: '10px',
    width: '20vw',
    [layout.mediaQuery.maxWidth.desktop]: {
      width: '30vw'
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      width: '40vw'
    }
  },
  container: {
    boxSizing: 'border-box',
    padding: '40px 15px 70px',
    [mediaQuery.maxWidth.desktop]: {
      padding: '20px 15px 50px'
    },
    [mediaQuery.maxWidth.tablet]: {
      padding: '30px 15px 50px'
    },
    [mediaQuery.maxWidth.mobileLarge]: {
      padding: '25px 16px'
    },
    backgroundColor
  },
  centeringRow: {
    ...layout.centerHorizontal,
    ...layout.centerVertical
  },
  centeringCol: {
    ...layout.centerHorizontal,
    textAlign: 'center'
  },
  headerCol: {
    marginTop: 24,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 16
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 12
    }
  },
  header: {
    ...fonts.h3,
    color: blackAt89
  },
  subheaderCol: {
    marginTop: 24,
    marginBottom: 56,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 16,
      marginBottom: 24
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 12,
      marginBottom: 20
    }
  },
  subheader: {
    ...fonts.p,
    color: blackAt62
  },
  cta: {
    ...buttons.buttonDefaults
  }
});

export default function Figment() {
  return (
    <Container fluid className={css(styles.container)}>
      <Row className={css(styles.centeringRow)}>
        <Col xs="12" md="10" className={css(styles.centeringCol, styles.headerCol)}>
          <img src={figmentBannerSvg} alt="Figment art" className={css(styles.image)} />
        </Col>
        <Col xs="12" md="10" className={css(styles.centeringCol, styles.headerCol)}>
          <h3 className={css(styles.header)}>{enOnly.Figment.banner_header}</h3>
        </Col>
        <Col xs="11" md="8" className={css(styles.centeringCol, styles.subheaderCol)}>
          <h5 className={css(styles.subheader)}>{enOnly.Figment.banner_copy}</h5>
        </Col>
        <Col xs="12" className={css(styles.centeringCol)}>
          <RoundedButton
            href={figmentUrlBanner()}
            styles={[styles.cta]}
            track="true"
            trackButtonPress="goto_figment"
            trackLocation="body"
          >
            {enOnly.Figment.cta}
          </RoundedButton>
        </Col>
      </Row>
    </Container>
  );
}
