import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, lumosTeal, blue7, whiteAt98, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const styles = StyleSheet.create({
  heightWrapper: {
    ...layout.centerHorizontal,
    ...layout.centerVertical,
    height: 224,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      height: 199
    }
  },
  wrapperTeal: {
    backgroundColor: lumosTeal
  },
  wrapperBlue: {
    backgroundColor: blue7
  },
  container: {
    ...layout.defaultContainerProperties
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  header: {
    ...fonts.h3,
    textAlign: 'center',
    color: whiteAt98,
    marginBottom: 12
  },
  cta: {
    ...buttons.buttonDefaults
  }
});

// change color background to coordinate with presence/absence of mindfulness module above
function isEn(locale) {
  return locale === 'en';
}

function JumboCta({ locale }) {
  return (
    <div className={css(styles.heightWrapper, isEn(locale) ? styles.wrapperTeal : styles.wrapperBlue)}>
      <Container fluid className={css(styles.container)}>
        <Row className={css(styles.centeringRow)}>
          <Col xs="12">
            <h3 className={css(styles.header)}>{i18n.t('homePage.JumboCta.header')}</h3>
          </Col>
          <ScrollReveal animation="fadeIn">
            <Col xs="12" className={css(styles.centeringRow)}>
              <RoundedButton
                href="https://www.lumosity.com/platform_check"
                styles={[styles.cta]}
                track="true"
                trackButtonPress="goto_signup_train_now"
                trackLocation="body"
              >
                {i18n.t('homePage.JumboCta.cta')}
              </RoundedButton>
            </Col>
          </ScrollReveal>
        </Row>
      </Container>
    </div>
  );
}

JumboCta.propTypes = {
  locale: PropTypes.string.isRequired
};

export default JumboCta;
