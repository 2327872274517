import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { blackAt62, fonts } from '@lumoslabs/react_lux';
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';
import * as layout from '../../styles/helpers/layout';
import dashboardEN from './images/dashboard.svg';
import dashboardDE from './images/dashboard.de.svg';
import dashboardFR from './images/dashboard.fr.svg';
import dashboardES from './images/dashboard.es.svg';
import dashboardPT from './images/dashboard.pt.svg';
import dashboardKO from './images/dashboard.ko.svg';
import dashboardJA from './images/dashboard.ja.svg';

const imageMap = {
  en: dashboardEN,
  de: dashboardDE,
  fr: dashboardFR,
  es: dashboardES,
  pt: dashboardPT,
  ko: dashboardKO,
  ja: dashboardJA
};

function dashboardImage(locale) {
  return imageMap[locale];
}

const styles = StyleSheet.create({
  container: {
    ...layout.defaultContainerProperties,
    overflowX: 'hidden'
  },
  headerRow: {
    ...layout.centerHorizontal,
    textAlign: 'center'
  },
  content: {
    ...fonts.p,
    color: blackAt62,
    marginTop: 8,
    marginBottom: 64,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 40
    }
  },
  headerCol: {
    marginBottom: 56,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 37
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginBottom: 24
    }
  },
  header: {
    ...fonts.h3
  },
  subheader: {
    ...fonts.h5
  },
  imageContainer: {
    ...layout.overflowImageContainer
  },
  image: {
    width: '150vw',
    maxWidth: 1336,
    marginTop: -100,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: -25
    }
  }
});

function BrainCare({ locale }) {
  return (
    <Container className={css(styles.container)}>
      <Row className={css(styles.headerRow)}>
        <Col xs="10" sm="12" className={css(styles.headerCol)}>
          <h3 className={css(styles.header)}>{i18n.t('homePage.BrainCare.header')}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <h5 className={css(styles.subheader)}>{i18n.t('homePage.BrainCare.section1.title')}</h5>
          <p className={css(styles.content)}>{i18n.t('homePage.BrainCare.section1.content')}</p>
        </Col>
        <Col xs="12" md="6">
          <h5 className={css(styles.subheader)}>{i18n.t('homePage.BrainCare.section2.title')}</h5>
          <p className={css(styles.content)}>{i18n.t('homePage.BrainCare.section2.content')}</p>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <h5 className={css(styles.subheader)}>{i18n.t('homePage.BrainCare.section3.title')}</h5>
          <p className={css(styles.content)}>{i18n.t('homePage.BrainCare.section3.content')}</p>
        </Col>
        <Col xs='12' md='6'>
          <h5 className={css(styles.subheader)}>
            {i18n.t('homePage.BrainCare.section4.title')}
          </h5>
          <p className={
            css(styles.content)
          }>
            {i18n.t('homePage.BrainCare.section4.content')}
          </p>
        </Col>
      </Row>
      <div className={css(styles.imageContainer)}>
        <ScrollReveal animation="expand">
          <img src={dashboardImage(locale)} className={css(styles.image)} alt={i18n.t('homePage.BrainCare.imgAlt')} />
        </ScrollReveal>
      </div>
    </Container>
  );
}

BrainCare.propTypes = {
  locale: PropTypes.string
};

BrainCare.defaultProps = {
  locale: 'en'
};

export default BrainCare;
