import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { fonts, blackAt62 } from '@lumoslabs/react_lux';
import i18n from '../../../utils/i18n';
import * as layout from '../../../styles/helpers/layout';

const styles = StyleSheet.create({
  container: {
    ...layout.defaultContainerProperties
  },
  headerCol: {
    marginBottom: 56,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 37
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginBottom: 24
    }
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  leftColumn: {
    ...layout.alignLeft,
    [layout.mediaQuery.maxWidth.tablet]: {
      ...layout.centerHorizontal
    }
  },
  imageCol: {
    height: 559,
    [layout.mediaQuery.maxWidth.desktopSmall]: {
      height: '110vw'
    }
  },
  rightColumn: {
    ...layout.centerHorizontal,
    flexDirection: 'column'
  },
  header: {
    ...fonts.h3,
    marginBottom: '50px'
  },
  subheader: {
    ...fonts.h5,
    margin: '8px 0 8px'
  },
  body: {
    ...fonts.p,
    color: blackAt62,
    margin: '8px 8px 8px 0px'
  }
});

function ResearchModule() {
  return (
    <Container className={css(styles.container)}>
      <Row>
        <Col xs="12" lg="6" className={css(styles.imageCol)} />
        <Col xs="12" lg="6">
          <h3 className={css(styles.header)}>{i18n.t('homePage.ResearchModule.header')}</h3>
          <p className={css(styles.subheader)}>{i18n.t('homePage.ResearchModule.section1.subheader')}</p>
          <p className={css(styles.body)}>{i18n.t('homePage.ResearchModule.section1.p1')}</p>
          <p className={css(styles.body)}>{i18n.t('homePage.ResearchModule.section1.p2')}</p>
          <p className={css(styles.subheader)}>{i18n.t('homePage.ResearchModule.section2.subheader')}</p>
          <p className={css(styles.body)}>{i18n.t('homePage.ResearchModule.section2.p1')}</p>
          <p className={css(styles.body)}>{i18n.t('homePage.ResearchModule.section2.p2')}</p>
          <p className={css(styles.subheader)}>{i18n.t('homePage.ResearchModule.section3.subheader')}</p>
          <p className={css(styles.body)}>{i18n.t('homePage.ResearchModule.section3.p1')}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ResearchModule;
